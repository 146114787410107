const functions = {
  reveal(slideUpPoint = 100) {
    const slideUpDivs = document.querySelectorAll('.-slide-up');
    const windowHeight = window.innerHeight;

    slideUpDivs.forEach(slideUpDiv => {
      const slideUpTop = slideUpDiv.getBoundingClientRect().top;

      if (slideUpTop < (windowHeight - slideUpPoint)) {
        slideUpDiv.classList.add('active');
      }
    });
  }
};
export default functions;
