<template>
  <div class="container pt-5">
    <ContactUs />
  </div>
</template>

<script>
import ContactUs from '@/components/v2/book/ContactUs'

export default {
  name: 'BookADemo',
  components: {
    ContactUs
  }
}
</script>