<template>
  <div class="px-md-5 px-xs-0 -content-division -meet-the-team position-relative -slide-up -z-index-2">
    <div class="row flex-content-center align-items-center">
      <h1 class="fw-bolder mb-5">Meet the Team</h1>
    </div>
    <div class="row">
      <div class="col-6 col-sm-4 col-md-3 mb-3" v-for="(member, i) in team" :key="i">
        <img alt="whiteStringsMembers" width="100" height="100" :src="member.image" class="-team-member-image" fetchpriority="low" loading="lazy">
        <div class="fw-bolder fs-5 my-2">{{ member.name }}</div>
        <p class="small text-muted">{{ member.position }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MeetTheTeam',
  data () {
    return {
      'team': [
        {
          'name': 'JDI',
          'position': 'President',
          'image': require('@/assets/graphics/v2/avatars/img6.png')
        },
        {
          'name': 'W@ltz',
          'position': 'Chief Technology Officer',
          'image': require('@/assets/graphics/v2/avatars/img12.png')
        },
        {
          'name': 'Orange',
          'position': 'Chief Marketing Officer',
          'image': require('@/assets/graphics/v2/avatars/img10.png')
        },
        {
          'name': 'Dion',
          'position': 'Business Manager',
          'image': require('@/assets/graphics/v2/avatars/img3.png')
        },
        {
          'name': 'Aura',
          'position': 'Project Manager',
          'image': require('@/assets/graphics/v2/avatars/img1.png')
        },

        {
          'name': 'Zeus',
          'position': 'Senior Developer',
          'image': require('@/assets/graphics/v2/avatars/img14.png')
        },
        {
          'name': 'Emman',
          'position': 'UI/UX Designer',
          'image': require('@/assets/graphics/v2/avatars/img13.png')
        },
        {
          'name': 'Gleanne',
          'position': 'Business Analyst / Quality Assurance',
          'image': require('@/assets/graphics/v2/avatars/img4.png')
        },
        {
          'name': 'JP',
          'position': 'Senior Developer',
          'image': require('@/assets/graphics/v2/avatars/img8.png')
        },
        {
          'name': 'Brian',
          'position': 'Senior Developer',
          'image': require('@/assets/graphics/v2/avatars/img2.png')
        },
        {
          'name': 'Justine',
          'position': 'Quality Assurance',
          'image': require('@/assets/graphics/v2/avatars/img7.png')
        },
        {
          'name': 'Niel',
          'position': 'Mobile Developer',
          'image': require('@/assets/graphics/v2/avatars/img9.png')
        },
        {
          'name': 'Renz',
          'position': 'Dev Ops',
          'image': require('@/assets/graphics/v2/avatars/img16.png')
        },
        {
          'name': 'Joms',
          'position': 'Mobile Developer',
          'image': require('@/assets/graphics/v2/avatars/img15.png')
        },
        {
          'name': 'Levi',
          'position': 'Intern',
          'image': require('@/assets/graphics/v2/avatars/img18.png')
        },
        {
          'name': 'Cess',
          'position': 'Intern',
          'image': require('@/assets/graphics/v2/avatars/img17.png')
        }
      ]
    }
  }
}
</script>