<template>
  <div class="container px-md-5 px-1 my-5">
    <div class="row w-100 m-auto justify-content-center align-items-center py-3 px-5">
      <h1 class="-font-now">Frequently Asked Questions</h1>
    </div>

    <div class="row w-100 m-auto flex-column justify-content-center align-items-center mb-5">
      <div class="col-lg-6 col-10" v-for="(faq, index) in faqs" :key="index">
        
        <div class="d-flex flex-column text-start border-bottom py-4">
          <a :name="'btn-faqs-question-' + index" role="button" v-b-toggle="'collapse-' + index" :id="'collapse-btn-' + index" class="-btn-faqs-question d-flex justify-content-between">
            <b>{{ faq.question }}</b>

            <button aria-label="toggle-button" name="toggle-button" type="button" class="navbar-toggle">
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
          </a>
          
          <b-collapse :id="'collapse-' + index"><p class="mt-2 -div-faqs-answer -text-justify py-3">{{ faq.answer }}</p></b-collapse>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'FrequentlyAskQuestions',
  data () {
    return {
      faqs: [
        {
          question: 'Why should I choose whiteStrings?',
          answer: 'WhiteStrings is a cutting-edge automation platform designed to enhance the operational efficiency of educational institutions. Through its hyperconnected environment, WhiteStrings empowers schools to manage their data efficiently, thereby reducing redundancy and ensuring the availability of reliable data points. Leveraging advanced cloud technology, WhiteStrings eliminates concerns about maintenance while offering convenient access to the platform from any location at any time.'
        },
        {
          question: 'Is whiteStrings secured?',
          answer: 'With WhiteStrings\' dedicated in-house security team, you can trust that your data is safeguarded at all times. Our strategic collaboration with the AWS team ensures the secure management and backup of your data, shielding it from potential cyber threats and penetrations. We are committed to maintaining the confidentiality and integrity of your data, prioritizing its protection against malicious actors and hackers.'
        },
        {
          question: 'Is it available on mobile?',
          answer: 'Indeed, whiteStrings Mobile has a specialized mobile application tailored for educational institutions, prioritizing essential information for students and their parents. Our vision encompasses the development of a comprehensive platform that transcends conventional boundaries, facilitating tasks such as tuition fee payments, official document requests, school store orders, and a myriad of forthcoming features. Pioneering the integration of NFC (Near Field Communication) technology within a school application, WhiteStrings Mobile emerges as the foremost solution locally, delivering unparalleled functionality and convenience to its users.'
        },
        {
          question: 'How do I subscribe to whiteStrings?',
          answer: 'To schedule a demonstration, kindly utilize our designated "Book a Demo" page and indicate a time that aligns with your schedule preferences. Upon confirmation, one of our dedicated account specialists will conduct a personalized presentation tailored to address your specific requirements. Rest assured, all existing concerns about your educational institution will be thoroughly addressed during the presentation, ensuring comprehensive solutions are provided to meet your needs effectively.'
        },
        {
          question: 'How long before I can use whiteStrings at my school after I subscribe?',
          answer: 'The whiteStrings implementation team is committed to ensuring the seamless integration of our platform into your educational institution. Collaborating closely with your team, we gather the requisite data to configure your platform effectively. The timeline for implementation is contingent upon several factors, including the size of the school, the digital availability of data, and the readiness of the school\'s administration or team for the transition. These considerations inform the duration of implementation, which typically ranges from three weeks to two months before the commencement of the school year.'
        },
      ],
    };
  },
}
</script>