<template>
  <div class="container pt-5">
    <PlatformFeatures />
    <WsPay />
  </div>
</template>

<script>
import PlatformFeatures from '@/components/v2/featues/PlatformFeatures'
import WsPay from '@/components/v2/featues/WsPay'
import functions from '@/functions.js';

export default {
  name: 'FeaturesV2',
  components: {
    PlatformFeatures,
    WsPay
  },
  mounted() {
    this.revealHandler = () => functions.reveal(100);
    window.addEventListener('scroll', this.revealHandler);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.revealHandler);
  },
}
</script>