import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    privacy: {
      activeTab: 'overview',
      lastUpdated: 'July 13, 2023',
      keyPoints: [
        {
          id: 'keypoint-1',
          title: 'What personal information do we process?',
          answer: 'When you visit, use, or navigate our Services, we may process personal information depending on how you interact with goodStrings Inc. and the Services, the choices you make, and the products and features you use. Click <a class="-click-here" data-target="title-1">here</a> to learn more.'
        },
        {
          id: 'keypoint-2',
          title: 'Do we process any sensitive personal information?',
          answer: 'We do not process sensitive personal information.'
        },
        {
          id: 'keypoint-3',
          title: 'Do we receive any information from third parties?',
          answer: 'We do not receive any information from third parties.'
        },
        {
          id: 'keypoint-3',
          title: 'How do we process your information?',
          answer: 'We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with the law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. <a class="-click-here" data-target="title-2">here</a> to learn more.'
        },
        {
          id: 'keypoint-4',
          title: 'In what situations and with which parties do we share personal information?',
          answer: 'We may share information in specific situations and with specific third parties. Click <a class="-click-here" data-target="title-3">here</a> to learn more'
        },
        {
          id: 'keypoint-5',
          title: 'How do we keep your information safe?',
          answer: 'We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Click <a class="-click-here" data-target="title-5">here</a> to learn more.'
        },
        {
          id: 'keypoint-6',
          title: 'What are your rights?',
          answer: 'Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Click <a class="-click-here" data-target="title-7">here</a> to learn more.'
        },
        {
          id: 'keypoint-7',
          title: 'How do you exercise your rights?',
          answer: 'The easiest way to exercise your rights is by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.'
        }
      ],
      tableOfContents: [
        {
          href: '#title-1',
          title: 'What information do we collect?'
        },
        {
          href: '#title-2',
          title: 'How do we process your information?'
        },
        {
          href: '#title-3',
          title: 'When and with whom do we share your personal information?'
        },
        {
          href: '#title-4',
          title: 'How long do we keep your information?'
        },
        {
          href: '#title-5',
          title: 'How do we keep your information safe?'
        },
        {
          href: '#title-6',
          title: 'Do we collect information from minors?'
        },
        {
          href: '#title-7',
          title: 'What are your privacy rights?'
        },
        {
          href: '#title-8',
          title: 'Controls for Do-No-Track features'
        },
        {
          href: '#title-9',
          title: 'Do we make updates to this notice?'
        },
        {
          href: '#title-10',
          title: 'How can you contact us about this notice?'
        },
        {
          href: '#title-11',
          title: 'How can you review, update or delete the data we collect from you?'
        }
      ],
      tableOfContentsDetails: [
        {
          id: 'title-1',
          title: 'What information do we collect?',
          details: `
            <p>
              We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.  
            </p>

            <div class="row w-100 flex-column justify-content-center align-items-center mb-3">
              <div class="col-12 -btn-privacy-content-box">
                <div class="d-flex flex-column text-start">
                  <a class="-btn-privacy-content d-flex justify-content-between" data-modal-data-id="1">
                    <b>The personal information you disclose to us</b>

                    <button type="button" class="navbar-toggle">
                      <span class="icon-bar"></span>
                      <span class="icon-bar"></span>
                    </button>
                  </a>
                </div>
              </div>

              <div class="col-12 -btn-privacy-content-box">
                <div class="d-flex flex-column text-start">
                  <a class="-btn-privacy-content d-flex justify-content-between" data-modal-data-id="2">
                    <b>Sensitive Information</b>

                    <button type="button" class="navbar-toggle">
                      <span class="icon-bar"></span>
                      <span class="icon-bar"></span>
                    </button>
                  </a>
                </div>
              </div>

              <div class="col-12 -btn-privacy-content-box">
                <div class="d-flex flex-column text-start">
                  <a class="-btn-privacy-content d-flex justify-content-between" data-modal-data-id="3">
                    <b>Information we collected from other sources</b>

                    <button type="button" class="navbar-toggle">
                      <span class="icon-bar"></span>
                      <span class="icon-bar"></span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          `,
          graphic: `ws-info-collection`
        },
        {
          id: 'title-2',
          title: 'How do we process your information?',
          details: `
            <p>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with the law. We may also process your information for other purposes with your consent.</p>
            <p>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:<p>

            <div class="col-12 -btn-privacy-content-box">
              <div class="d-flex flex-column text-start">
                <a class="-btn-privacy-content d-flex justify-content-between" data-modal-data-id="4">
                  <b>To deliver and facilitate the delivery of services to the user.</b>

                  <button type="button" class="navbar-toggle">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                  </button>
                </a>
              </div>
            </div>

            <div class="col-12 -btn-privacy-content-box">
              <div class="d-flex flex-column text-start">
                <a class="-btn-privacy-content d-flex justify-content-between" data-modal-data-id="5">
                  <b>To respond to user inquiries/offer support to users.</b>

                  <button type="button" class="navbar-toggle">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                  </button>
                </a>
              </div>
            </div>

            <div class="col-12 -btn-privacy-content-box">
              <div class="d-flex flex-column text-start">
                <a class="-btn-privacy-content d-flex justify-content-between" data-modal-data-id="6">
                  <b>To enable user-to-user communications.</b>

                  <button type="button" class="navbar-toggle">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                  </button>
                </a>
              </div>
            </div>

            <div class="col-12 -btn-privacy-content-box">
              <div class="d-flex flex-column text-start">
                <a class="-btn-privacy-content d-flex justify-content-between" data-modal-data-id="7">
                  <b>To request feedback.</b>

                  <button type="button" class="navbar-toggle">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                  </button>
                </a>
              </div>
            </div>

            <div class="col-12 -btn-privacy-content-box">
              <div class="d-flex flex-column text-start">
                <a class="-btn-privacy-content d-flex justify-content-between" data-modal-data-id="8">
                  <b>To comply with our legal obligations.</b>

                  <button type="button" class="navbar-toggle">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                  </button>
                </a>
              </div>
            </div>
          `,
          graphic: `ws-process-information`
        },
        {
          id: 'title-3',
          title: 'When and with whom do we share your personal information?',
          details: `
            <p>We may share information in specific situations described in this section and/or with the following third parties.</p>
            <p class="fw-bolder">We may need to share your personal information in the following situations:</p>

            <div class="col-12 -btn-privacy-content-box">
              <div class="d-flex flex-column text-start">
                <a class="-btn-privacy-content d-flex justify-content-between" data-modal-data-id="9">
                  <b>Business Transfers</b>

                  <button type="button" class="navbar-toggle">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                  </button>
                </a>
              </div>
            </div>

            <div class="col-12 -btn-privacy-content-box">
              <div class="d-flex flex-column text-start">
                <a class="-btn-privacy-content d-flex justify-content-between" data-modal-data-id="10">
                  <b>Business Partners</b>

                  <button type="button" class="navbar-toggle">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                  </button>
                </a>
              </div>
            </div>
          `,
          graphic: `ws-share-information`
        },
        {
          id: 'title-4',
          title: 'How long do we keep your information?',
          details: `
            <p>We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</p>
            <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice unless a more extended retention period is required or permitted by law (such as tax, accounting, or other legal requirements)</p>
            <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
          `
        },
        {
          id: 'title-5',
          title: 'How do we keep your information safe?',
          details: `
            <p>We aim to protect your personal information through a system of organizational and technical security measures.</p>
            <p>We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, the transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment</p>
          `
        },
        {
          id: 'title-6',
          title: 'Do we collect information from minors?',
          details: `
            <p>We do not knowingly collect data from or market to children under 18 years of age.</p>
            <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under the age of 18, please contact us at <a href="mailto:info@whitestrings.io">info@whitestrings.io</a>.</p>`
        },
        {
          id: 'title-7',
          title: 'What are your privacy rights?',
          details: `
            <p>You may review, change, or terminate your account at any time.</p>
            <p>Suppose you are located in the Philippines and you believe we are unlawfully processing your personal information. In that case, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <a href="https://privacy.gov.ph/contactus/" target="_blank">https://privacy.gov.ph/contactus/</a></p>
            <h6 class="fw-bolder">Withdrawing your consent</h6>
            <p>If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us using the contact details provided in the section "<a class="-click-here" data-target="title-10">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>" below.</p>
            <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>
            <p>If you have questions or comments about your privacy rights, email us at <a href="mailto:info@whitestrings.io">info@whitestrings.io</a>.</p>
          `
        },
        {
          id: 'title-8',
          title: 'Controls for Do-Not-Track features',
          details: `
            <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>`
        },
        {
          id: 'title-9',
          title: 'Do we make updates to this notice?',
          details: `
            <p>Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>
            <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>
          `
        },
        {
          id: 'title-10',
          title: 'How can you contact us about this notice?',
          details: `
            <p>If you have questions or comments about this notice, you may email us at <a href="mailto:info@whitestrings.io">info@whitestrings.io</a> or by post to:</p>
            <p>GoodStrings Inc</p>
            <p>Lot 6 Blk.6, Apo st., Mountain Heights,</p>
            <p>Caloocan City, Metro Manila</p>
            <p>Philippines 1400</p>
          `
        },
        {
          id: 'title-11',
          title: 'How can you review, update or delete the data we collect from you?',
          details: `
            <p>You have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information by contacting us by clicking <a class="-click-here" data-target="title-10">here</a>.</p>
          `
        }
      ],
      modalContent: null,
      privacyModalData: [
        {
          data_id: 1,
          question: `The personal information you disclose to us.`,
          answer: `
            <p>The personal information we collect may include the following:</p>
            <ul>
              <li>Names</li>
              <li>Phone numbers</li>
              <li>Email addresses</li>
            </ul>
          `
        },
        {
          data_id: 2,
          question: `Sensitive information`,
          answer: `
            <p>We do not process sensitive information</p>
            <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>
          `
        },
        {
          data_id: 3,
          question: `Information we collected from other sources`,
          answer: `
            <p>We may collect limited data from public databases, marketing partners, and outside sources.</p>
            <p>In order to enhance our ability to provide relevant marketing, offers, and services to you and update our records, we may obtain information about you from other sources, such as public databases, joint marketing partners, affiliate programs, data providers, and from other third parties. This information includes mailing addresses, job titles, email addresses, and phone numbers, for purposes of targeted advertising and event promotion.</p>
          `
        },
        {
          data_id: 4,
          question: `To deliver and facilitate the delivery of services to the user.`,
          answer: `We may process your information to provide you with the requested service.`
        },
        {
          data_id: 5,
          question: `To respond to user inquiries/offer support to users.`,
          answer: `We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.`
        },
        {
          data_id: 6,
          question: `To enable user-to-user communications.`,
          answer: `We may process your information if you choose to use any of our offerings that allow for communication with another user.`
        },
        {
          data_id: 7,
          question: `To request feedback.`,
          answer: `We may process your information when necessary to request feedback and to contact you about your use of our Services.`
        },
        {
          data_id: 8,
          question: `To comply with our legal obligations.`,
          answer: `We may process your information to comply with our legal obligations, respond to legal requests, and exercise, establish, or defend our legal rights.`
        },
        {
          data_id: 9,
          question: `Business Transfers`,
          answer: `We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.`
        },
        {
          data_id: 10,
          question: `Business Partners`,
          answer: `We may share your information with our business partners to offer you certain products, services, or promotions.`
        }
      ]
    },
      
    terms: {
      type: 1,
      class: ' -list-level-1',
      list: [
        {
          type: '1',
          id: 'term-1',
          class: ' -list-level-2',
          title: '1. Website Usage',
          detail: null,
          list: [
            {
              id: 'term-1-1',
              title: '1.1 Permitted Use',
              detail: 'The website is intended for informational and educational purposes related to <b>[school]</b>. You may use the website solely for lawful purposes and in compliance with these Terms.'
            },
            {
              id: 'term-1-2',
              title: '1.2 Prohibited Use',
              detail: 'You must not use the website for any illegal or unauthorized purpose. Prohibited activities include, but are not limited to:',
              type: 'a',
              class: ' -list-level-3',
              list: [
                { 
                  id: 'term-1-2-1',
                  title: null,
                  detail: 'a) Attempting to gain unauthorized access to the website or its servers.'
                },
                { 
                  id: 'term-1-2-2',
                  title: null,
                  detail: 'b) Interfering with or disrupting the website\'s functionality..'
                },
                { 
                  id: 'term-1-2-3',
                  title: null,
                  detail: 'c) Uploading or distributing malicious software or content.'
                },
                { 
                  id: 'term-1-2-4',
                  title: null,
                  detail: 'd) Engaging in any activity that violates applicable laws or regulations.'
                },
              ]
            }
          ],
        },
        {
          type: '1',
          id: 'term-2',
          class: ' -list-level-2',
          title: '2. User Accounts',
          detail: null,
          list: [
            {
              id: 'term-2-1',
              title: '2.1 Account Creation',
              detail: 'User accounts on the website can be created by <b>[school]</b> or individual users. The school administration reserves the right to create and manage user accounts, and individual users may also create their own accounts subject to the school\'s approval and any additional terms or conditions set by the school.'
            },
            {
              id: 'term-2-2',
              title: '2.2 Account Termination:',
              detail: 'whiteStrings and <b>[school]</b> reserve the right to suspend or terminate user accounts at its discretion, especially in cases of violations or misuse of the website or non-compliance with these Terms.'
            },
            {
              id: 'term-2-3',
              title: '2.3 Types of Accounts',
              detail: 'The website offers different types of accounts based on user roles within the school system. These account types may include:',
              type: 'a',
              class: ' -list-level-3',
              list: [
                {
                  id: 'term-2-3-1',
                  title: 'a) Admin Account',
                  detail: 'An admin account is for authorized administrators or staff members who have administrative privileges and can manage various aspects of the website and user accounts.'
                },
                {
                  id: 'term-2-3-2',
                  title: 'b) Teacher Account',
                  detail: 'A teacher account is for authorized teachers who can access and utilize educational resources, communicate with students and parents, and manage their class-related activities on the website.'
                },
                {
                  id: 'term-2-3-3',
                  title: 'c) Student Account:',
                  detail: 'A student account is for registered students of <b>[school]</b> who can access educational materials, participate in online discussions, submit assignments, and engage in other activities relevant to their education.'
                },
                {
                  id: 'term-2-3-4',
                  title: 'd) Parent Account',
                  detail: 'A parent account is for parents or legal guardians of students enrolled at <b>[school]</b>. It allows parents to access information related to their child\'s education, communicate with teachers, and receive updates from the school.'
                },
              ],
              post_detail: 'The availability and specific features of each account type may vary and are subject to the policies and guidelines set by <b>[school]</b>.'
            }
          ]
        },
        {
          type: '1',
          id: 'term-3',
          class: ' -list-level-2',
          title: '3. Fees, Payments, and Refunds',
          detail: null,
          list: [
            {
              id: 'term-3-1',
              title: '3.1 Payment Processing',
              detail: 'Payments of the Fees are processed by our third-party payment processors BUX and Paymongo (collectively, the “Payment Processors”). The Payment Processors are solely responsible for handling the payments. You acknowledge and agree that whiteStrings does not process, store, or have access to your payment information. Please review the Payment Processors\' terms and privacy policies to understand how they handle your payment information.'
            }
          ]
        },
        {
          type: '1',
          id: 'term-4',
          class: ' -list-level-2',
          title: '4. Privacy and Data Protection',
          detail: null,
          list: [
            {
              id: 'term-4-1',
              title: '4.1 Data Collection',
              detail: ' <b>[school]</b> may collect and process personal information in accordance with applicable privacy laws. By using the website, you consent to the collection, use, storage, and processing of your personal information as outlined in our Privacy Policy.'
            },
            {
              id: 'term-4-2',
              title: '4.2 Cookies and Tracking Technologies',
              detail: 'The website may use cookies or similar technologies to enhance your user experience. By using the website, you consent to the use of cookies in accordance with our Cookie Policy.'
            },
          ]
        },
        {
          type: '1',
          id: 'term-5',
          class: ' -list-level-2',
          title: '5. Intellectual Property',
          detail: null,
          list: [
            {
              id: 'term-5-1',
              title: '5.1 Ownership',
              detail: 'All content on the website, including text, images, videos, and logos, are the property of whiteStrings and <b>[school]</b> or its licensors and are protected by copyright and other intellectual property laws.'
            },
            {
              id: 'term-5-2',
              title: '5.2 Use of Content',
              detail: 'You may not reproduce, modify, distribute, transmit, display, perform, or create derivative works from any content on the website without prior written permission from whiteStrings or the respective content owner. Unauthorized use of the website\'s content may violate copyright laws and other intellectual property rights.'
            }
          ]
        },
        {
          type: '1',
          id: 'term-6',
          class: ' -list-level-2',
          title: '6. Third-Party Links and Content',
          detail: null,
          list: [
            {
              id: 'term-6-1',
              title: '6.1 Third-Party Links',
              detail: 'The website may contain links to third-party websites or resources. whiteStrings is not responsible for the availability, accuracy, or content of such third-party websites or resources. The inclusion of any links does not imply endorsement by whiteStrings.'
            }
          ],
          post_detail: 'You acknowledge and agree that whiteStrings shall not be liable for any damages or losses arising from your use of such third-party websites or resources.'
        },
        {
          type: '1',
          id: 'term-7',
          class: ' -list-level-2',
          title: '7. Limitation of Liability',
          detail: null,
          list: [
            {
              id: 'term-7-1',
              title: '7.1 Disclaimer',
              detail: 'The website and its content are provided on an "as is" and "as available" basis. whiteStrings makes no warranties, expressed or implied, and disclaims all warranties, including but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement. whiteStrings does not guarantee the accuracy, completeness, or reliability of any content on the website or the results obtained from using the website.'
            },
            {
              id: 'term-7-2',
              title: '7.2 Limitation of Liability',
              detail: 'To the maximum extent permitted by applicable law, whiteStrings shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in any way connected with your use of or inability to use the website, including but not limited to damages for loss of profits, goodwill, data, or other intangible losses, regardless of whether whiteStrings has been advised of the possibility of such damages.'
            }
          ]
        },
        {
          type: '1',
          id: 'term-8',
          class: ' -list-level-2',
          title: '8. Changes to Terms and Conditions',
          detail: null,
          list: [
            {
              id: 'term-8-1',
              title: '8.1 Modifications',
              detail: 'whiteStrings reserves the right to modify or replace these Terms at any time without prior notice. The most current version of the Terms will be posted on the website. It is your responsibility to review the Terms periodically for any changes. Your continued use of the website after any modifications to the Terms constitutes your acceptance of the modified Terms.'
            }
          ]
        },
        {
          type: '1',
          id: 'term-9',
          class: ' -list-level-2',
          title: '9. Governing Law and Disputes',
          detail: null,
          list: [
            {
              id: 'term-9-1',
              title: '9.1 Governing Law',
              detail: 'These Terms shall be governed by and construed in accordance with the laws of [Insert governing law and jurisdiction].'
            },
            {
              id: 'term-9-2',
              title: '9.2 Dispute Resolution',
              detail: 'Any dispute arising out of or relating to these Terms or using the website shall be resolved through good faith negotiations between the parties. If the parties are unable to reach a resolution, the dispute shall be submitted to binding arbitration in accordance with the rules and procedures of the chosen arbitration organization.'
            },
            {
              id: 'term-9-3',
              title: '9.3 Severability',
              detail: 'If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.'
            },
          ]
        }
      ]
    },

    searchKeyword: null,
    searchResults: null
  },
  mutations: {
    changePrivacyActiveTab: (state, tab) => {
      state.searchResults = null
      state.searchKeyword = null
      state.privacy.activeTab = tab
    },
    setModalContent: (state, keyPoint) => {
      state.privacy.modalContent = keyPoint
    },
    setSearchKeyword: (state, keyword) => {
      state.searchKeyword = keyword
    },
    setSearchResults: (state, value) => {
      state.searchResults = value
    }
  },
  actions: {
  },
  modules: {
  }
})
