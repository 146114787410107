<template>
  <div class="px-md-5 px-xs-0 -meet-the-team">
    <div class="row justify-content-center align-items-center pt-5 px-3 px-md-0">
      <figure class="-team-ws-logo py-5">
        <!-- <wsLogo height="100" width="100%"/> -->
        <img fetchpriority="high" src="@/assets/graphics/v2/ws_logo.webp" alt="ws-logo" srcset="">
      </figure>
      <h1 class="mb-2 -font-now">The team behind whiteStrings</h1>
      <p class="-font-inter pt-5 pt-md-4">
        Meet the visionary whiteStrings Development Team<br>
        a dynamic force committed to revolutionizing the education industry in the Philippines.<br class="d-none d-lg-block">
        With a passion for innovation, our dedicated team is driven to empower the education industry<br class="d-none d-lg-block"> 
        by crafting a new generation of School Automation Platform. From transformative technologies<br class="d-none d-lg-block">
        to forward-thinking strategies, we're on a mission to usher in a new era of learning,<br class="d-none d-lg-block">
        ensuring brighter futures for students across the nation.
      </p>
    </div>

    <div class="col bg-white -section-vision-mission -content-division">
      <div class="row px-md-2 -section-ws-vision mb-3" id="sectionWsVision">
        <div class="col-md-6 col-sm-12 px-2 d-flex flex-column justify-content-center align-items-center align-items-md-start order-2 order-md-1 pt-3 pt-md-0">
          <h2 class="fw-bolder">Vision</h2>
          <p class="text-center text-md-start">To be recognized as the benchmark of software development for inspiring tech-companies and developing future trends in the technology industry.</p>
        </div>
        <div class="col-md-6 col-sm-12 order-1 order-md-2">
          <div class="row justify-content-center align-items-center">
            <figure>
              <!-- <wsVision height="300" width="100%" /> -->

              <img fetchpriority="low" loading="lazy" src="../../../../public/graphics/ws_vision.webp" height="300" alt="ws-vision" srcset="">
            </figure>
          </div>
        </div>
      </div>

      <div class="row px-md-2 -section-ws-mission -content-division position-relative -slide-up -z-index-2" id="sectionWsMission">
        <div class="col-md-6 col-sm-12">
          <div class="row justify-content-center align-items-center">
            <figure>
              <!-- <wsMission height="300" width="100%" /> -->

              <img fetchpriority="low" loading="lazy" src="../../../../public/graphics/ws_mission.webp" height="300" alt="ws-mision" srcset="">
            </figure>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 px-2 d-flex flex-column justify-content-center align-items-center align-items-md-end pt-3 pt-md-0">
          <h2 class="fw-bolder">Mission</h2>
          <p class="text-center text-md-end">To be the preferred technology company providing a world-class leading innovation in different industries around the world.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import wsLogo from '@/components/svgs/wsLogo'
// import wsVision from '@/components/svgs/wsVision'
// import wsMission from '@/components/svgs/wsMission'

export default {
  name: 'TeamWs',
  // components: {
    // wsLogo,
    // wsVision,
    // wsMission
  // }
}
</script>