<template>
  <div class="container px-md-5 px-xs-0 py-5">
    <div class="row w-100 m-auto justify-content-center align-items-center py-5">
      <h1 class="-font-now">Companies we work with</h1>
    </div>

    <div class="row w-100 m-auto flex-row justify-content-center py">
      <div class="slider">
        <div class="slide-track">
          <div class="slide -clients-logo" v-for="logo, index in schoolLogos" :key="index">
            <img
              :src="logo"
              alt="whiteStrings"
              loading="lazy"
              fetchpriority="low"
              width="120"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlmlcLogo from '../../../../public/graphics/schools/almlc.webp'
import BuLogo from '../../../../public/graphics/schools/bu.webp'
import BinanLogo from '../../../../public/graphics/schools/cdnms_binan.webp'
import CalambaLogo from '../../../../public/graphics/schools/cdnms_calamba.webp'
import HsLogo from '../../../../public/graphics/schools/cdnms_hs.webp'
import IlaganLogo from '../../../../public/graphics/schools/cdnms_ilagan.webp'
import RoxasLogo from '../../../../public/graphics/schools/cdnms_roxas.webp'
import OlmpcLogo from '../../../../public/graphics/schools/olpmc.webp'
import SclcLogo from '../../../../public/graphics/schools/sclc.webp'
import SjcbLogo from '../../../../public/graphics/schools/sjcb.webp'
import TlcnbsLogo from '../../../../public/graphics/schools/tlcnbs.webp'

export default {
  data () {
    return {
      schoolLogos:[
        AlmlcLogo,
        BuLogo,
        BinanLogo,
        CalambaLogo,
        HsLogo,
        IlaganLogo,
        RoxasLogo,
        OlmpcLogo,
        SclcLogo,
        SjcbLogo,
        TlcnbsLogo,
        AlmlcLogo,
        BuLogo,
        BinanLogo,
        CalambaLogo,
        HsLogo,
        IlaganLogo,
        RoxasLogo,
        OlmpcLogo,
        SclcLogo,
        SjcbLogo,
        TlcnbsLogo,
    
      ]
    }
  },
  name: "CompaniesWeWorkWith",
  
};

</script>
