<template>
  <svg class="-ws-search-icon" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_282_208)">
      <path d="M10.0625 18.375C14.6534 18.375 18.375 14.6534 18.375 10.0625C18.375 5.47163 14.6534 1.75 10.0625 1.75C5.47163 1.75 1.75 5.47163 1.75 10.0625C1.75 14.6534 5.47163 18.375 10.0625 18.375Z" class="-icon-primary-color" stroke="#404040" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15 10C15 7.23858 12.7614 5 10 5" stroke="#138819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M19.25 19.25L17.5 17.5" class="-icon-primary-color" stroke="#404040" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_282_208">
        <rect width="21" height="21" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'SearchIcon'
}
</script>