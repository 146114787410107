<template>
  <div id="mobileAppLink" class="container px-md-5 px-xs-0 -mobile-app-link py-md-5">
    <div class="d-flex align-items-center justify-content-center py-5 py-md-3">
      <span class="me-0 me-md-5">
        <a aria-label="App Store" class="btn -btn-app-link p-0" href="https://www.apple.com/ph/app-store/" target="_blank">
          <!-- <AppStoreBtn /> -->
          <img
            src="../../../../public/graphics/btn_app_store.webp"
            alt="btn_app_store"
            loading="eager"
            fetchpriority="high">
        </a>
      </span>
      <span>
        <a aria-label="Google Play"  class="btn -btn-app-link p-0" href="https://play.google.com/store/games?hl=en&gl=US" target="_blank">
          <!-- <GPlayBtn /> -->
          <img
            src="../../../../public/graphics/btn_gplay.webp"
            alt="btn_gplay"
            loading="eager"
            fetchpriority="high">
        </a>
      </span>
    </div>
  </div>
</template>

<script>
// import AppStoreBtn from '@/components/svgs/appStoreBtn'
// import GPlayBtn from '@/components/svgs/gPlayBtn'

export default {
  name: 'AppLink',
  // components: {
  //   AppStoreBtn,
  //   GPlayBtn
  // }
}
</script>