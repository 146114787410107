export const func = {
  delay: (function(){
    var timer = 0
    return function(callback, ms){
      clearTimeout (timer)
      timer = setTimeout(callback, ms)
    }
  })(),
  moveToDiv (id, delay = 0) {
    setTimeout(function () {
      let topOffset = document.getElementById(id.replace('#', '')).offsetTop;
      if (topOffset > 100) topOffset = topOffset - 73

      window.scrollTo({ top: topOffset, behavior: 'smooth' });
    }, delay)
  },
  
}