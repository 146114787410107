<template>
  <div class="pt-5">
    <WebsiteIntro />
    <MobileBanner />
    <CompaniesWeWorkWith />
    <FrequentlyAskQuestions />
    
  </div>
</template>

<script>
import WebsiteIntro from '@/components/v2/home/WebsiteIntro'
import MobileBanner from '@/components/v2/home/MobileBanner'
import CompaniesWeWorkWith from '@/components/v2/home/CompaniesWeWorkWith'
import FrequentlyAskQuestions from '@/components/v2/home/FrequentlyAskQuestions'
import functions from '@/functions.js';

export default {
  name: 'HomeV2',
  components: {
    WebsiteIntro,
    MobileBanner,
    CompaniesWeWorkWith,
    FrequentlyAskQuestions
  },
  mounted() {
    this.revealHandler = () => functions.reveal(70);
    window.addEventListener('scroll', this.revealHandler);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.revealHandler);
  },
}
</script>