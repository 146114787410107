<template>
  <div class="px-md-5" :style="bannerCss">
    <div class="container">
      <div class="d-flex flex-column justify-content-start align-items-start position-relative">
        <!-- <handyPhone class="-img-mobile-banner" /> -->
        <img
          src="../../../../public/graphics/handy_phone.webp"
          class="-img-mobile-banner"
          alt="handy-phone"
          loading="lazy"
          fetchpriority="low">
        <div class="d-flex flex-column align-items-start position-absolute text-white -mobile-banner-text">
          <span class="-txt-ws">white<b>Strings Mobile</b></span>
          <span class="-txt-first-super-app">The first super app for schools.</span>
          <a name="mobile" aria-label="mobile" class="-txt-link-mobile" target="_blank" href="/mobile">
            <span class="sr-only">WhiteStrings</span>
            Learn more
          </a>
        </div>
      </div>
    </div>
      
  </div>
</template>

<script>
// import handyPhone from '@/components/svgs/handyPhone'

export default {
  name: 'MobileBanner',
  // components: {
  //   handyPhone
  // },
  data() {
    return {
      bannerCss: {
        background: `linear-gradient(
          rgba(83, 83, 83, 0.4), 
          rgba(83, 83, 83, 0.2)
        ), url(${require('../../../../public/graphics/mobile_banner_bg.webp')})`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `cover`,
      }
    }
  }
}
</script>