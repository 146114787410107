<template>
  <div class="container">
    <div class="px-md-5 px-xs-0 px-lg-0 -school-centered-app position-relative">
      <div class="row pt-5 pb-2">
          <div class="col-12 px-5 pt-5">
            <label class="h1 fw-bolder">
              The School Centered App
            </label>
            <p class="mb-1">More than just your typical school mobile app.</p>
          </div>
      </div>

      <div class="row px-4 px-md-5 px-lg-0">
        <div class="col-6 col-md-4 col-lg-3 d-flex flex-column align-items-start align-items-md-center justify-content-between order-2 order-md-1 -school-feature-list">
          <div class="-school-feature-item -feature-profile">
            <div class="d-flex flex-row align-items-center justify-content-center text-start text-md-center -scaling">
              <img alt="profile" src="@/assets/icons/v2/mobile_menu/profile.svg" width="200" height="200" class="-school-feature-img me-2 me-md-3"> Student Profile
            </div>
          </div>
          <div class="-school-feature-item -feature-grades">
            <div class="d-flex flex-row align-items-center justify-content-center text-start text-md-center -scaling -scaling-4s">
              <img alt="grades" src="@/assets/icons/v2/mobile_menu/grades.svg" width="200" height="200" class="-school-feature-img me-2 me-md-3"> Grades
            </div>
          </div>
          <div class="-school-feature-item -feature-schedules">
            <div class="d-flex flex-row align-items-center justify-content-center text-start text-md-center -scaling">
              <img alt="schedules" src="@/assets/icons/v2/mobile_menu/schedules.svg" width="200" height="200" class="-school-feature-img me-2 me-md-3"> Class Schedules
            </div>
          </div>
          <div class="-school-feature-item -feature-notif">
            <div class="d-flex flex-row align-items-center justify-content-center text-start text-md-center -scaling -scaling-4s">
              <img alt="notifications" src="@/assets/icons/v2/mobile_menu/notif.svg" width="200" height="200" class="-school-feature-img me-2 me-md-3"> Notifications
            </div>
          </div>
          <div class="-school-feature-item -feature-nfc">
            <div class="d-flex flex-row align-items-center justify-content-center text-start text-md-center -scaling -scaling-5s">
              <img alt="nfc" src="@/assets/icons/v2/mobile_menu/nfc.svg" width="200" height="200" class="-school-feature-img me-2 me-md-3"> NFC and QR Code Enabled
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-6 order-1 order-md-2">
          <!-- <SchoolCenteredAppSvg class="-school-centered-app-img" /> -->

          <img
            src="../../../../public/graphics/school_centered.webp"
            class="-school-centered-app-img"
            alt="mobile_ad"
            loading="lazy"
            fetchpriority="low">
        </div>
        <div class="col-6 col-md-4 col-lg-3 d-flex flex-column align-items-start align-items-md-center justify-content-between order-3 -school-feature-list">
          <div class="-school-feature-item -feature-events">
            <div class="d-flex flex-row align-items-center justify-content-center text-start text-md-center -scaling -scaling-5s">
              <span class="order-2 order-md-1">School Events</span>  <img alt="events" src="@/assets/icons/v2/mobile_menu/events.svg" width="200" height="200" class="-school-feature-img order-1 order-md-2 me-2 me-md-0 ms-0 ms-md-3">
            </div>
          </div>
          <div class="-school-feature-item -feature-classes">
            <div class="d-flex flex-row align-items-center justify-content-center text-start text-md-center -scaling">
              <span class="order-2 order-md-1">Online Classes</span> <img alt="classes" src="@/assets/icons/v2/mobile_menu/classes.svg" width="200" height="200" class="-school-feature-img order-1 order-md-2 me-2 me-md-0 ms-0 ms-md-3">
            </div>
          </div>
          <div class="-school-feature-item -feature-feed">
            <div class="d-flex flex-row align-items-center justify-content-center text-start text-md-center -scaling -scaling-4s">
              <span class="order-2 order-md-1">Campus Feed</span> <img alt="feed" src="@/assets/icons/v2/mobile_menu/feed.svg" width="200" height="200" class="-school-feature-img order-1 order-md-2 me-2 me-md-0 ms-0 ms-md-3">
            </div>
          </div>
          <div class="-school-feature-item -feature-transaction">
            <div class="d-flex flex-row align-items-center justify-content-center text-start text-md-center -scaling -scaling-4s">
              <span class="order-2 order-md-1">Transaction History</span> <img alt="transaction" src="@/assets/icons/v2/mobile_menu/transaction.svg" width="200" height="200" class="-school-feature-img order-1 order-md-2 me-2 me-md-0 ms-0 ms-md-3">
            </div>
          </div>
          <div class="-school-feature-item -feature-wallet">
            <div class="d-flex flex-row align-items-center justify-content-center text-start text-md-center -scaling">
              <span class="order-2 order-md-1">Wallet Balance</span> <img alt="wallet" src="@/assets/icons/v2/mobile_menu/wallet.svg" width="200" height="200" class="-school-feature-img order-1 order-md-2 me-2 me-md-0 ms-0 ms-md-3">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import SchoolCenteredAppSvg from '@/components/svgs/schoolCenteredApp'

export default {
  name: 'SchoolCenteredApp',
  // components: {
  //   SchoolCenteredAppSvg
  // }
}
</script>