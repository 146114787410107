<template>
  <div class="-mobile-page-container">
    <MobileAppBanner />
    <SchoolCenteredApp />
    <ScrollableMobileFeatures />
    <AppLink />
      
  </div>
</template>

<script>
import MobileAppBanner from '@/components/v2/mobile/MobileAppBanner'
import SchoolCenteredApp from '@/components/v2/mobile/SchoolCenteredApp'
import ScrollableMobileFeatures from '@/components/v2/mobile/ScrollableMobileFeatures'
import AppLink from '@/components/v2/mobile/AppLink'

export default {
  name: 'MobileApp',
  components: {
    MobileAppBanner,
    SchoolCenteredApp,
    ScrollableMobileFeatures,
    AppLink
  }
}
</script>