<template>
  <div class="container px-md-5 px-xs-0">
    <div
      id="fadingBanner"
      class="col -top-0"
      style="
        position: sticky;
        z-index: 1;
        opacity: 1;
        transition: opacity 3s ease-out;
      "
    >
      <div class="row w-100 m-auto justify-content-center pt-5 col-md-12">
        <h1 class="mb-2 -font-now -school-changing-text">
          Schools
          <span
            :class="'typewriter-text ' + currentWordColor"
            id="typewriterText"
          ></span
          ><span :class="'-typewriter-cursor ' + currentWordColor">|</span>
        </h1>
        <p class="-website-intro-description">
          A new generation of <br class="d-md-none" />automation and artificial
          intelligence
        </p>
      </div>
      <div class="row w-100 m-auto -animating-dashboard-image" style="padding-bottom: 100px">
        <div class="col-md-12 d-flex align-items-center justify-content-center" id="imgSystemPlatformContainer">
          <!-- <platformDashboard
            :height="platformSvgHeight"
            :width="platformSvgWidth"
            customCss="transition: width 1s ease-out;"
          /> -->

          <div id="imgSystemPlatform" class="transition img-system-platform" v-if="false"></div>
          
          <img 
            id="imgSystemPlatform" 
            class="transition" 
            :width="platformSvgWidth" 
            :height="platformSvgHeight" 
            fetchpriority="high"
            alt="system_platform" 
            src="../../../../public/graphics/system_platform.webp"
            >
        </div>
      </div>
    </div>
    <div class="col bg-white position-relative px-3" style="z-index: 2">
      <div class="row w-100 mx-auto -mb-7 -section-hyper-connected px-md-2 position-relative -slide-up -z-index-2">
        <div class="col-md-6 col-sm-12 d-flex flex-column justify-content-center align-items-center align-items-md-start">
          <h2 class="fw-bolder text-center text-md-start">A Hyperconnected Environment</h2>
          <p class="text-center text-md-start">
            white<b>Strings</b> brings you all the features you need in one
            platform. From Enrollment to Graduation, an end-to-end school
            automation platform provides seamless process in a hyperconnected
            environment.
          </p>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="row justify-content-center align-items-center">
            <!-- <hyperConnected height="300" width="100" /> -->

            <img 
              class="w-100 h-auto" 
              fetchpriority="low" 
              loading="lazy"
              alt="hyper"
              srcset="@/assets/graphics/v2/hyper_connected_480.webp 480w, @/assets/graphics/v2/hyper_connected_640.webp 640w, @/assets/graphics/v2/hyper_connected.webp 786w"
              sizes="480px, 640px, 786px">
          </div>
        </div>
      </div>
      <div class="row w-100 mx-auto -mb-7 -doing-work px-md-2 position-relative -slide-up -z-index-2" >
        <div class="col-md-6 col-sm-12 -section-doing-work-image">
          <div class="row justify-content-center align-items-center">
            <!-- <doingWork height="300" width="100" /> -->

            <img 
              class="w-100 h-auto" 
              fetchpriority="low" 
              loading="lazy" 
              alt="doing" 
              srcset="@/assets/graphics/v2/doing_work.webp 480w, @/assets/graphics/v2/doing_work.webp 640w, @/assets/graphics/v2/doing_work_920.webp 920w, @/assets/graphics/v2/doing_work.webp 1028w"
              sizes="480px, 640px, 920px, 1028px">
          </div>
        </div>
        <div class="col-md-6 col-sm-12 -section-doing-work-text d-flex flex-column justify-content-center align-items-center align-items-md-end">
          <h2 class="fw-bolder text-center text-md-end">
            Doing most of the work for you
          </h2>
          <p class="text-center text-md-end">
            Automate most of your operations, eliminating tedious work that is
            not relevant to each role, whiteStrings helps you do less work for
            more results.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import platformDashboard from "@/components/svgs/platformDashboard";
// import hyperConnected from "@/components/svgs/hyperConnected";
// import doingWork from "@/components/svgs/doingWork";
export default {
  name: "WebsiteIntro",
  components: {
    // platformDashboard,
    // hyperConnected,
    // doingWork,
  },
  data() {
    return {
      wordIndex: 0,
      words: ["Reimagined", "Reengineered", "Automated", "Empowered"],
      wordColors: ["-green", "-yellow", "-blue", "-red"],
      currentLoopText: "Reimagined",
      currentWordColor: "-green",
      sleepTime: 100,

      percentage: 0,
      platformSvgWidth: "95%",
      platformSvgHeight: "100%",
    };
  },
  methods: {
    typewriterEffect: function () {
      const el = document.getElementById("typewriterText");
      if (!el) return;

      clearTimeout(this.typewriterTimeout);

      let currentLoopText = this.words[this.wordIndex];

      let i = 0;
      const typeNextCharacter = () => {
        if (i <= currentLoopText.length) {
          el.innerHTML = currentLoopText.substring(0, i);
          i++;
          this.typewriterTimeout = setTimeout(typeNextCharacter, this.sleepTime);
        } else {
          this.typewriterTimeout = setTimeout(this.eraseText, this.sleepTime * 10);
        }
      };

      this.typewriterTimeout = setTimeout(typeNextCharacter, 0);
    },
    eraseText: function () {
      const el = document.getElementById("typewriterText");
      if (!el) return;
      let currentLoopText = this.words[this.wordIndex];
      let i = currentLoopText.length;
      const eraseNextCharacter = () => {
        if (i >= 0) {
          el.innerHTML = currentLoopText.substring(0, i);
          i--;
          this.typewriterTimeout = setTimeout(eraseNextCharacter, this.sleepTime);
        } else {
          this.wordIndex = (this.wordIndex + 1) % this.words.length;
          this.currentWordColor = this.wordColors[this.wordIndex];
          this.typewriterEffect();
        }
      };
      this.typewriterTimeout = setTimeout(eraseNextCharacter, 0);
    }
  },
  mounted() {

    if (window.innerWidth < 480) {
      document.getElementById("fadingBanner").style.position = "static";
    } else {
      document.getElementById("fadingBanner").style.position = "sticky";
    }
    let vm = this
    setTimeout(function () {
      vm.typewriterEffect();
    }, 100)

    const element = document.getElementById("imgSystemPlatformContainer");

    window.addEventListener("scroll", () => {
      const viewportHeight = window.innerHeight;
      const scrollTop = window.scrollY;
      const elementOffsetTop = element.offsetTop;
      const elementHeight = element.offsetHeight;

      if (window.innerWidth > 480) {
        const distance = scrollTop + viewportHeight - elementOffsetTop;
        this.percentage = Math.round(
          distance / ((viewportHeight + elementHeight) / 100)
        );
      }
    });
  },
  watch: {
    percentage(newValue, prevValue) {
      if (newValue > prevValue) {
        if (newValue > 40 && newValue <= 50) {
          document.getElementById("imgSystemPlatform").style.width = "80%";
        } else if (newValue > 50 && newValue <= 60) {
          document.getElementById("imgSystemPlatform").style.width = "70%";
          document.getElementById("fadingBanner").style.opacity = 0.2;
        } else if (newValue > 60) {
          document.getElementById("imgSystemPlatform").style.width = "60%";
          document.getElementById("fadingBanner").style.opacity = newValue <= 70 ? 0.09 : 0.07;
        }
      } else {
        if (newValue > 80) {
          document.getElementById("imgSystemPlatform").style.width = newValue > 70 ? "74%" : "65%";
          document.getElementById("fadingBanner").style.opacity = newValue > 70 ? 0.1 : 0.08;
        } else if (newValue > 60) {
          document.getElementById("imgSystemPlatform").style.width = "80%";
          document.getElementById("fadingBanner").style.opacity = newValue > 65 ? 0.3 : 1;
        } else {
          document.getElementById("imgSystemPlatform").style.width = "95%";
          document.getElementById("fadingBanner").style.opacity = 1;
        }
      }

      document.getElementById("fadingBanner").style.position = newValue > 110 ? "relative" : "sticky";

    },
  },
};
</script>

<style>
  .transition{
    transition: width 1s ease-out
  }
  /***
  .img-system-platform {
    height: 719px; 
    width: 95%;
    background-image: url('../../../../public/graphics/system_platform.webp');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
  }
  */
</style>