<template>
  <div class="px-md-5 px-xs-0 -platform-features">
    <div class="col-12 -platform-features-intro d-flex flex-column align-items-center">
      <label class="-txt-ws d-block" id="wsLabel">white<b>Strings</b></label>
      <div class="col-10 col-lg-8 -features-description">
        A School Automation Platform designed in a hyperconnected environment that provides a complete solution fo the school's daily operations by eliminationg tedious work processes and operates as one platform across different departments.
      </div>
      <div id="imgFeaturesIntroImageContainer" class="w-100">
        <!-- <plaformFeaturesSvg class="-features-intro-image" width="55%" height="100%"/> -->

        <img 
          id="imgFeaturesIntroImage" 
          class="-features-intro-image" 
          width="55%"
          fetchpriority="high"
          alt="platform_features" 
          src="../../../../public/graphics/platform_features.webp"
          >
      </div>
    </div>

    <div class="row -z-index-2 position-relative d-none d-md-flex justify-content-center align-items-center mb-3">
      <div class="col-8">
        <div class="row py-2 -plaform-features-title position-sticky" id="platformFeaturesTitle" style="top: 100px;">
          <div class="col-6"><h3 class="fw-bolder">School Management</h3></div>
          <div class="col-6"><h3 class="fw-bolder">Learning Management</h3></div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">Online Enrollment</div>
          <div class="col-6 text-end fw-bold">Campus & Class Feed</div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">Schedule Management</div>
          <div class="col-6 text-end fw-bold">Subject-based Online Classrooms</div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">Subject & Curriculum Management</div>
          <div class="col-6 text-end fw-bold">Student Progress Tracking</div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">Cashiering System</div>
          <div class="col-6 text-end fw-bold">Dynamic Online Workbooks</div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">School E-Commerce</div>
          <div class="col-6 text-end fw-bold">Document Sharing</div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">Library System</div>
          <div class="col-6 text-end fw-bold">Online Grading System</div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">Financial Management</div>
          <div class="col-6 text-end fw-bold">Online Attendance</div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">Student & Employee Data Management</div>
          <div class="col-6 text-end fw-bold">Automated Payment Reminders</div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">Guidance System</div>
          <div class="col-6 text-end fw-bold">Email, SMS & in-App Notifications</div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">Clinic System</div>
          <div class="col-6 text-end fw-bold">Online Payment Ready</div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">Smart Id System</div>
          <div class="col-6 text-end fw-bold">Anti-Cheating System</div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">Data Analytics & Dashboard</div>
          <div class="col-6 text-end fw-bold">Online Assessments & Examinations</div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">Audit Trail</div>
          <div class="col-6 text-end fw-bold">Student Analysis</div>
        </div>
        <div class="row py-2 -plaform-features-item">
          <div class="col-6 text-start fw-bold">and more...</div>
          <div class="col-6 text-end fw-bold">and more...</div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
// import plaformFeaturesSvg from '@/components/svgs/platformFeatures'

export default {
  name: 'PlatformFeatures',
  // components: {
  //   plaformFeaturesSvg
  // },
  data () {
    return {
      percentage: 0,
      laptopImageWidth: 55
    }
  },
  mounted () {
    const element = document.getElementById('wsLabel');

    window.addEventListener('scroll', () => {
      const viewportHeight = window.innerHeight;
      const scrollTop = window.scrollY;
      const elementOffsetTop = element.offsetTop;
      const elementHeight = element.offsetHeight;
      if (window.innerWidth > 480) {
        const distance = (scrollTop + viewportHeight) - elementOffsetTop;
        this.percentage = Math.round(distance / ((viewportHeight + elementHeight) / 100));
      }
      const platformFeatures = document.querySelectorAll('.-plaform-features-item')
      for (let platformFeature of platformFeatures) {
        const platformFeatureTopOffset = platformFeature.getBoundingClientRect().top
        let opacity = 1
        if (platformFeatureTopOffset < 140) {
          opacity = 0
        } else if (platformFeatureTopOffset < 160) {
          opacity = 0.2
        } 
        platformFeature.style.opacity = opacity
      }
    })
  },
  watch: {
    percentage(newValue, prevValue) {
      const img = document.getElementById('imgFeaturesIntroImage');
      const imgContainer = document.getElementById('imgFeaturesIntroImageContainer');
      const setImgStyles = (width, top, left, transform, position, zIndex, opacity) => {
        img.style.width = `${width}%`;
        img.style.top = top;
        img.style.left = left;
        img.style.transform = transform;
        img.style.position = position;
        img.style.zIndex = zIndex;
        img.style.opacity = opacity;
      };
      const setContainerStyles = (padding, height) => {
        imgContainer.style.padding = padding;
        imgContainer.style.height = height;
      };
      if (newValue > prevValue) {
        if (newValue < 90) {
          if (this.laptopImageWidth < 90) {
            this.laptopImageWidth += 12;
          } else {
            this.laptopImageWidth = 90;
            setImgStyles(90, '55%', '51%', 'translate(-50%, -50%)', 'fixed', 1, 0.1);
            setContainerStyles('5em 0', '500px');
          }
        } else {
          this.laptopImageWidth = 90;
          setImgStyles(90, '55%', '51%', 'translate(-50%, -50%)', 'fixed', 1, 0.1);
          setContainerStyles('5em 0', '500px');
        }
      } else {
        if (newValue > 79) {
          if (newValue <= 90) {
            this.laptopImageWidth = Math.max(this.laptopImageWidth - 12, 55);
            setImgStyles(this.laptopImageWidth, 'unset', 'unset', '', 'static', '', 1);
            setContainerStyles('5em 0', 'unset');
          }
        } else {
          this.laptopImageWidth = 55;
          setImgStyles(55, 'unset', 'unset', '', 'static', '', 1);
          setContainerStyles('0', 'unset');
        }
      }
    }
  }
}
</script>