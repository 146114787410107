<template>
  <div class="container pt-5">
    <TeamWs />
    <CoreValues />
    <MeetTheTeam />
  </div>
</template>

<script>
import TeamWs from '@/components/v2/about/TeamWs'
import CoreValues from '@/components/v2/about/CoreValues'
import MeetTheTeam from '@/components/v2/about/MeetTheTeam'
import functions from '@/functions.js';

export default {
  name: 'HomeV2',
  components: {
    TeamWs,
    CoreValues,
    MeetTheTeam
  },
  mounted() {
    this.revealHandler = () => functions.reveal(80);
    window.addEventListener('scroll', this.revealHandler);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.revealHandler);
  },
}
</script>